#blog{
    background-color: #EEEEEE;
    background-repeat: no-repeat;
    background-position-y: 40%;
    background-position-x: -20%;
}
.blog-jumbotron{
   h1{
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 55px;
    line-height: 116.6%;
    color: #FF6700;
   }
   h1+p{
    font-size: 17px;
    line-height: 32px;
    color: #023E4F;
    font-family: 'Sarabun', sans-serif;
   }
}

.blogs{
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 350px);
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    .blog-card{
        color: #FF6700;
        border-radius: 10px !important;
        box-shadow:  0px 15px 30px rgba(0, 0, 0, 0.12);
        .card-body div{
            p:first-of-type{
                font-weight: bolder;
            }
            p{
                color: #FF6700 !important;
                font-size: 14px;
            }
        }
    }
    p{
        color: #565656;
        font-size: 15px;
        line-height: 142%
    }
    button.orange{
        width: 150px !important;
        font-size: 15px !important;
        a{
            color: #fff;
        }
    }
}

.page-link {
    line-height: normal; 
    background-color: transparent; 
    border: none;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: transparent;
    border:none;
}
.w-font {
    margin: 0 auto;

    font-family: 'Graphik';

    /* Hot Graient */

    background: linear-gradient(97.1deg, #C41740 4.8%, #EA9C28 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

@media only screen and (max-width: 425px) {
    .blogs{
        grid-template-columns: 310px;
    }
}