.about-jumbotron{
    .textArea{
        background-repeat: no-repeat;
        background-position:right;
    }
   }
   h1{
       font-family:'Open Sans', sans-serif;
       font-weight: 800;
       font-size: 27px;
       line-height: 116.6%;
       color: #FF6700;
   }
   p{
       color: #333333;
       font-family: 'Sarabun', sans-serif;
       font-size: 17px;
       font-weight: 400;
       line-height: 34px;
   }

   

@media only screen and (max-width: 768px){
    .about-jumbotron{
       h1{
           font-size: 15px;
       }
       p{
           font-size: 13px;
           line-height: 20px;
       }
    }
}