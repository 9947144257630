  .singleBox{
    padding-top: 10%;
    padding-bottom: 2px;
    border: 1px solid #D3D3D3;
    text-align: center;
    color: #4F4F4F;
    background-color: #FAFAFA;
    border-radius: 8px;
    h6{
        padding: 15px 0px 10px 0;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 15px;
        line-height: 29px;
        text-transform: uppercase;
    }
    p{
        font-family: 'Sarabun', sans-serif;
        font-size: 15px;
        padding-right: 25px;
        padding-left: 25px;
    }
    img{
        max-width: 130px !important;
    }
  }