#howitworkssection{
  .videoSection{
    text-align: center;
    padding-top: 10%;
    padding-bottom: 6%;
    video{
        width: 60vw;
        height: 70vh;
    }
}

.faqs {
    text-align: center;
    margin-left: 8%;
    margin-right: 8%;
}

.accordion {
    background-color: #FF6700;
    color: #fff;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-weight: bold;
    font-size: 20px;
    transition: 0.4s;
  }
  
  .active, .accordion:hover {
    background-color: #FE7756;
  }
  
  .accordion:after {
    content: '\002B';
    color: #fff;
    font-size: 32px;
    float: right;
    margin-left: 5px;
    margin-top: -10px;
  }
  
  .active:after {
    content: "\2212";
  }
  
  .panel {
    padding: 0px 6%;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    border: 1px solid #FF6700;
    transition: max-height 0.2s ease-out;
    p, ul{
        text-align: left;
        font-family: 'Sarabun', sans-serif;;
        font-size: 17px;
        line-height: 150%;
        color: #4F4F4F;
    }
    p.toptext{
        font-size: 19px;
        font-weight: bold;
    }
    ul li{
      list-style-type: none;
      margin-left: -20px;
      line-height: 170%;
    }
    ul li::before {
     content: '\2192';
     padding-right: 10px;
    }
  }
}

  @media only screen and (max-width: 768px) {
    #howitworkssection{
      .videoSection{
        padding-top: 6%;
        padding-bottom: 5%;
        video{
            width: 80vw;
            height: 60vh;
        }
    }
    }
  }

  @media only screen and (max-width: 435px) {
    #howitworkssection{
      .videoSection{
        video{
            width: 83vw;
            height: 35vh;
        }
    }
    }
  }