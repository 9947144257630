.top{
    background-repeat: no-repeat;
    background-color: black;
    background-position: right;
    padding: 4% 9%;
    background-position-x: 90%;
    color: #fff;
    .socials{
        align-items: flex-end;
    }
    h4{
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 30px;
        line-height: 120%;
        letter-spacing: -0.03em;
    }
    p{
        font-family: 'Sarabun', sans-serif;
        font-size: 14px;
        line-height: 150%;
        padding-bottom: 12px;
        color: #fff !important;
    }
    input{
        // max-width: 80%;
        height: 50px;
        border:none;
        color: #fff;
        outline: none;
        border-radius: 5px 0 0 5px;
        background-color: black !important;
        padding-left: 33px;
        ::placeholder{
            color:  #fff;
            font-family: 'Sarabun', sans-serif;
            font-size: 14px !important;
            line-height: 19px;
        }
    }
    input+button{
        background-color: #fff;
        color: #FF6700;
        border:none;
        outline: none;
        height: 50px;
        width: 150px;
        border-radius: 0 5px 5px 0;
        font-family: 'Sarabun', sans-serif;
        font-size: 16px !important;
        line-height: 19px;
    }
}

.bottom{
    padding: 7% 3%;
    p{
        font-family: 'Sarabun', sans-serif;
        font-size: 17px;
        padding-top: 20px;
        line-height: 119.3%;
        letter-spacing: 0.02em;
        color: #273043;
    }
    ul {
        padding-top: 20px;
        li{
            list-style-type: none;
            padding-inline-end: 30px;
            display: inline;
            font-family: 'Sarabun', sans-serif;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
            color: #FF6700;
            a{
                display: inline;
                cursor: pointer;
                color: #FF6700;
            }
            a:hover{
                color: #FF6700af;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    footer{
        text-align: center;
        input{
            border-radius: 5px !important;
            align-items: flex-end;
            width:35vw !important;
            padding-left: 15px !important;
        }
        input+button{
            width: 35vw !important;
            border-radius: 5px !important;
            margin-top: 5%;
        }
        .socials{
            margin-top: 5%;
            align-items: flex-end;
        }
    }
}

@media only screen and (max-width: 425px){
    footer{
        .inputs{
            max-width: 100vw !important;
        }
        .top{
            padding-left: 20px;
            padding-right: 20px;
        }
        input{
         
            width:50vw !important;
           
        }
        input+button{
          
            width:30vw !important;
        }
        .bottom div.row{
            text-align: left;
            margin-top: -35px !important;
            p{
                order: 4;
               padding-left: 30px;
            }
            >ul{
                order: 2;
               
                li{
                    line-height: 280%;
                    padding-left: 0 !important;
                    display: block;
                }
          }
        }
    }
}

@media only screen and (max-width: 425px){

}