.App{
  overflow-x: hidden;
}
.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown-menu{
  margin-top: -5px;
}

.inner-menu{
  margin-left: 6em;
}

button.orange{
  width: 170px;
  padding-inline-start: 5px;
  height: 42px;
  background-color: #FF6700;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  border:none;
  font-size:18px;
  position: relative;
  line-height:23.86px;
  span{
      position: absolute;
      right: 20px;
  }
}
button.orange:hover{
  background-color: #f75c24 !important;
}
button.orange:active{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

div.wayapayJumbotron{
  background-color: #fff;
  padding-left: 6%;
  background-repeat: no-repeat;
  background-position-y: 140%;
  > div div:first-of-type{
      align-self: center;
  }
  h2{
   font-family: 'Open Sans', sans-serif;
   font-weight: 700;
   font-size: 42px;
   line-height: 116.6%;
   color: #4F4F4F;
  }
  h2+p{
   font-size: 15px;
   line-height: 160%;
   color: #4F4F4F;
   word-spacing: 3px;
   max-width: 90%;
   font-family: 'Sarabun', sans-serif;
  }
  img{
      max-width: 430px !important;
     margin-left: -90px;
     filter: drop-shadow(0px 2px 2px #4e4c4d9a);
  }
}

.theGrids{
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.loading{
  margin-left: 47vw !important;
}

.hiding{
  display: none;
}

.showing{
  display: block;
}

.tou-jumbotron{
  background-repeat: no-repeat;
  background-position:right;
  height: 80% !important;
  h2{
      font-family:'Open Sans', sans-serif;
      font-weight: 500;
      font-size: 57px;
      line-height: 116.6%;
      color: #FF6700;
  }
}

.documentPages{
  article{
      margin-left: 10vw;
      margin-right: 10vw;
      margin-bottom: 5vh;
  }
}

@media only screen and (max-width: 768px){
  .theGrids{
    grid-template-columns: repeat(2, 40vw);
  }
}

@media only screen and (max-width: 425px){
  .theGrids{
    grid-template-columns: 300px;
  }
  div.wayapayJumbotron{
    padding-top:0;
    background-color: #fff;
    background-image: none !important;
    h2{
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 23px;

     }
     h2+p{
      font-size: 13px;
     }
     img{
      max-width: 230px !important;
      text-align: center;
      margin-left: 10px;
  }
  }
  
}
