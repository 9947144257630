// miscelleneous styles




.home-jumbotron{
    width: 100%;
    min-height: 100vh;
    // background-image: url("/images/home-bg.png");
    // background-color: #FF6700;
    background-size: cover;
    background-blend-mode: darken;
    background-repeat: no-repeat;
    overflow-y: visible;

    .goDown{
        text-align: center;
        button{
            outline: 0;
            border: 0;
            background: none;
            margin-right: 20%;
            img{
                max-width: 90%;
            }
        }
    }
   h1{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 125%;
    // color: #fff;
   }
   h1+p{
    font-size: 15px;
    line-height: 160%;
    // color: #fff;
    font-family: 'Sarabun', sans-serif;
   }
//    img[alt='jumbotron']{
//        position: absolute;
//        max-width: 48%;
//        right: 8%;
//        top: 16%;
//    }
    .md-m{
        margin-bottom: -24px;
    }
}

.genericJumbo{
    width: 100vw !important;
    .gjButton{
        .btn-orange{
            background-color: #FF6700;
            color: #fff;
            border-radius: 4px;
            padding-inline-start: 10px;
            padding-inline-end: 10px;
            font-size: 14px;
            transition: all 1s ease-out;
        }
        .btn-orange:hover{
            background-color: #fff;
            color: #FF6700;
        }

        .btn-link{
            color: #FF6700;
            transition: all 1s ease-out;
        }
        .btn-orange:hover{
            color: #FE7756;
        }
         // li.link-btn:hover, li.link-btn:active{
         //     background-color: #FE7756;
         // }
    }
   img[alt='jumbotron']{
    //    position: absolute;
       max-width: 58%;
    //    right: 8%;
    //    top: 16%;
   }
   .mx-40{
       margin-left: 170px;
       margin-right: 170px;
   }
}

section.categories{
    padding: 5% 10%;
    position: relative;
    h6{
        text-transform: uppercase;
        font-family: 'Open Sans' sans-serif;
        font-weight: bold;
        font-size: 15px;
        line-height: 116.6%;
        color: #4F4F4F;
    }
    p{
        font-family: 'Sarabun' sans-serif;
        font-size: 14px;
        line-height: 150%;
        color: #4F4F4F;
    }
    img{
        max-width: 110px;
    }
    // button.floatChat{
    //     border: none;
    //     outline: none;
    //     background: none;
    //     position: absolute;
    //     right: 5%;
    //     bottom: 4%;
    // }
    h1{
        font-family:'Open Sans', sans-serif;
        font-weight: 800;
        font-size: 30px;
        line-height: 116.6%;
        color: #FF6700;
    }
    .text-orange-tick{
        color: #FF6700;
    } 
    .text-orange-fade{
        color:#FE7756;
    }
    
}

section.features{
    background-color: #FF6700;
    background-blend-mode: darken;
    background-repeat: no-repeat;
    height: auto;
    padding-top: 4%;
    h4{
        color: #fff;
    }
    h6{
        padding-block-start: 3%;
        font-family: 'Open Sans', sans-serif;
        font-size:20px;
          color: #fff;
        text-align: left;
        line-height: 30px; 
        text-transform: capitalize;
    }
    p{
        text-align: left;
        font-family: 'Sarabun', sans-serif;
        font-size: 15px;
        line-height: 30px;
        font-weight: 400;
        color: #fff;
    }
    .infographic{
        margin: 3% auto;
        padding: 2%;
        max-width: 90vw;
        padding: 4em 10em;
        .singlePhone Image{
            max-width: 92%;
        }
        .singlePhone:before {
            content: ' ';
            background: #fff;
            display: inline-block;
            position: absolute;
            left: 96.5%;
            margin-top: 53px;
            margin-bottom: 70px !important;
            width: 3px;
            height: 82%;
            z-index: 400;
            border-radius: 1rem;
        }
        .singlePhone::after {
            content: ' ';
            background: #fff;
            display: inline-block;
            position: absolute;
            left: 90%;
            margin-top: 83%;
            width: 30px;
            height: 3px;
            z-index: 400;
            border-radius: 1rem;
        }
        .special-row{
            margin-left: -28px !important;
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 4fr;
            Image{
                max-width: 78%;
            }
            h6{
                font-family: 'Open Sans' sans-serif;
                font-weight: bold;
                font-size: 14px;
                line-height: 116.6%;
            }
            p{
                font-family: 'Sarabun', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                color: #FFFFFF;
            }
        }
    }
}

.features-two{
    padding: 6% 9%;
    ul.timeline {
        list-style-type: none;
        position: relative;
        padding-left: 2.5rem;
        p{
            padding-block-end: 10px;
        }
    }
    
     /* Timeline vertical line */
    ul.timeline:before {
        content: ' ';
        background: #FF6700;
        display: inline-block;
        position: absolute;
        left: 17.5px;
        width: 2px;
        height: 72%;
        z-index: 400;
        border-radius: 1rem;
    }
    
    li.timeline-item {
        margin: 0px 0;
        strong{
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            font-size: 20px;
            line-height: 116.6%;
            letter-spacing: -0.03em;
            color: #FF6700;
        }
        p{
            font-family: 'Sarabun', sans-serif;
            font-size: 18px;
            line-height: 150%;
            color: #333333;
            opacity: 0.6;
        }
    }

    
    
    /* Timeline item circle marker */
    li.timeline-item::before {
        content: ' ';
        background: #FF6700;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #fff;
        left: 11px;
        width: 14px;
        height: 14px;
        z-index: 400;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .thevideoSection{
        text-align: center;
        padding-bottom: 6%;
        video{
            width: 700px;
            height: 400px;
        }
    }
}

.wayaPos{
    .switchPos{
        background-color: gainsboro;
        .posButton{
            color: gray;
            background: none;
            font-weight: 600;
        }
        .posButton:hover,.active{
            color: #FFFFFF;
            background-color: #FF6700;
        }
}
}


.last-jumbotron {
    background-color: #FF6700;
    background-blend-mode: darken;
    background-repeat: no-repeat;
    border-radius: 50px;
    padding: 5% 6% 0% 6% !important;
    img:first-of-type{
        max-width: 85%;
    }
    h1{
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        font-size: 68px;
        line-height: 102.6%;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }
    // p{
    //     font-family: 'Sarabun', sans-serif;
    //     font-weight: 500;
    //     font-size: 16px;
    //     line-height: 150%;
    //     color: #FFFFFF;
    //     max-width: 400px;
    // }
}

@media only screen and (max-width: 768px){
    #homesection{
        .home-jumbotron{
            margin-top: -80px !important;
            .goDown{
                display: none;
            }
           h1{
            font-size: 35px;
           }
           h1+p{
            font-size: 13px;
            line-height: 180%;
           }
           img[alt='jumbotron']{
            max-width: 68%;
            // right: 10vw;
            // top: 76vh;
        }
        .mx-40{
            margin-left: 0px;
            margin-right: 0px;
        }
        }
        .home-jumbotron .bannerLinks{

            > a {
                height: 42.5px;
                max-width: 170px !important;
               
            }
        }
        .genericJumbo{
            margin-top: 80vh;
        }
        section.features {
            padding: 0 1% !important;
            h4{
                padding-top: 4%;
            }
            .infographic{
                margin: 0% auto;
                padding: 1em 10em;
                .singlePhone{
                    display: none;
                }  
            }
        }
        .features-two{
            .thevideoSection{
               
                video{
                    width: 80vw !important;
                    height: 60vh;
                }
            }
        }
        .last-jumbotron{
            padding-top: 0%;
            .col{
                padding-bottom: 8%;
                 img {
                    height: 42.5px;
                    max-width: 180px !important;
                   
                }
            }
        }
    }
}

@media only screen and (max-width: 435px){
    #homesection{
        .home-jumbotron{
            margin-top: -80px !important;
            .goDown{
                display: none;
            }
           h1{
            font-size: 35px;
           }
           h1+p{
            font-size: 13px;
            line-height: 180%;
           }
           img[alt='jumbotron']{
            max-width: 88%;
            // right: 10vw;
            // top: 76vh;
        }
        }
        .home-jumbotron .bannerLinks{

            > a {
                height: 42.5px;
                max-width: 100px !important;
               
            }
        }
        .genericJumbo{
            margin-top: 50vh;
            margin-left: -19vw;
            padding-right: 4vw;
        }
        .last-jumbotron{
            text-align: center;
            border-radius: 50px;
            margin-left: 5%;
            width: 90vw;
            img[alt='Phone in hand']{
                display: none;
            }
            h1{
                font-size: 47px;
            }
            p{
                font-size: 19px;
            }
        }
        .features-two{
            li.timeline-item {
               
                strong{
                    font-size: 15px;
                }
                p{
                    font-size: 13px;
                }
            }
            .thevideoSection{
               
                video{
                    width: 85vw !important;
                    height: 33vh;
                }
            }
        }
        section.features {
            .infographic{
                margin: 0% auto;
                padding: 1em 1em;
            }
        }
        .last-jumbotron{
            padding-top: 10% !important;
            .col{
               div.row{
                   text-align: center;
                padding-left: 10%;
                padding-right: 20%;
                img{
                    margin-bottom: 10%;
                }
               }
            }
        }
    }
}

// .wayaPosFeatures{
//     background-image: url('public/featuresbg.png');
// }
